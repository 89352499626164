import { Handle, Position } from 'reactflow';

const InHandle = () => {
  return (
    <Handle
      className="-top-2.5 -z-10 h-5 w-5"
      type="target"
      position={Position.Top}
    />
  );
};

export default InHandle;
