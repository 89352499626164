import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

const Node = ({ canCollapse = false, onToggleCollapse, children }) => {
  return (
    <div className="flex flex-col rounded-md bg-neutral-300 shadow-2xl">
      {children}
      {canCollapse && (
        <button onClick={onToggleCollapse}>
          <EllipsisHorizontalIcon className="h-4 w-full text-center" />
        </button>
      )}
    </div>
  );
};

export default Node;
