import { useEffect } from 'react';

import {
  nodeTypes,
  useAudioasis,
  useAudioasisDispatch,
} from '../../context/audioasisContext';

import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  MiniMap,
} from 'reactflow';

import ContextMenuEdge from '../Common/ContextMenuEdge';
import ContextMenuFlow from '../Common/ContextMenuFlow';
import ContextMenuNode from '../Common/ContextMenuNode';
import CustomControls from '../Common/CustomControls';

import 'reactflow/dist/style.css';

const Flow = () => {
  const { nodes, edges, audioContext, isPlaying } = useAudioasis();
  const dispatch = useAudioasisDispatch();

  useEffect(() => {
    isPlaying ? audioContext.resume() : audioContext.suspend();
  }, []);

  const defaultEdgeOptions = {
    animated: isPlaying,
    type: 'smoothstep',
  };

  const handleNodesChange = (nodeUpdates) => {
    dispatch({
      type: 'nodeUpdate',
      payload: { nodeUpdates: nodeUpdates },
    });
  };

  const handleEdgesChange = (edgeUpdates) => {
    dispatch({
      type: 'edgeUpdate',
      payload: { edgeUpdates: edgeUpdates },
    });
  };

  const handleNodesDelete = (nodes) => {
    dispatch({
      type: 'nodeDelete',
      payload: [...nodes],
    });
  };

  const handleOnConnect = (edgeParams) => {
    dispatch({
      type: 'edgeAdd',
      payload: { edgeParams: edgeParams },
    });
  };

  const handleNodeContextMenu = (e, node) => {
    e.preventDefault();
    dispatch({
      type: 'openNodeContextMenu',
      payload: { x: e.clientX, y: e.clientY, node: node },
    });
  };

  const handleFlowContextMenu = (e) => {
    e.preventDefault();
    if (e.target.className === 'react-flow__pane') {
      dispatch({
        type: 'openFlowContextMenu',
        payload: { x: e.clientX, y: e.clientY },
      });
    }
  };

  const handleEdgeContextMenu = (e) => {
    e.preventDefault();
    dispatch({
      type: 'openEdgeContextMenu',
      payload: { x: e.clientX, y: e.clientY },
    });
  };

  const handleClick = (e, node) => {
    dispatch({
      type: 'closeAllContextMenus',
    });
  };

  return (
    <ReactFlow
      onClick={handleClick}
      onContextMenu={handleFlowContextMenu}
      nodes={nodes}
      onNodesChange={handleNodesChange}
      edges={edges}
      onEdgesChange={handleEdgesChange}
      onEdgeContextMenu={handleEdgeContextMenu}
      onNodesDelete={handleNodesDelete}
      onNodeContextMenu={handleNodeContextMenu}
      onConnect={handleOnConnect}
      nodeTypes={nodeTypes}
      defaultEdgeOptions={defaultEdgeOptions}
      connectionLineType={ConnectionLineType.SmoothStep}
      snapToGrid
      snapGrid={[15, 15]}
      fitView
      deleteKeyCode={['Backspace', 'Delete']}
    >
      <CustomControls />
      <ContextMenuFlow />
      <ContextMenuNode />
      <ContextMenuEdge />
      <MiniMap />
      <Background variant={BackgroundVariant.Dots} gap={15} size={1} />
    </ReactFlow>
  );
};

export default Flow;
