import { useAudioasisDispatch } from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import InHandle from '../Layout/InHandle';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const BitCrusher = ({ id, data }) => {
  const dispatch = useAudioasisDispatch();

  const handleUpdateProperty = (updatedProperty) => {
    const newData = { ...data, ...updatedProperty };
    dispatch({
      type: 'nodeDataUpdate',
      payload: {
        id: id,
        data: newData,
      },
    });
  };

  return (
    <>
      <InHandle />
      <Node>
        <Header name="Bit Crusher" color="orange" />
        <Property name="bits">
          <input
            className="nodrag"
            type="range"
            step={1}
            min={1}
            max={16}
            value={data.bits}
            onChange={(e) =>
              handleUpdateProperty({ bits: Number(e.target.value) })
            }
          />
          <span className="text-right font-light">{data.bits}</span>
        </Property>
        <Property name="normfreq">
          <input
            className="nodrag"
            type="range"
            step={0.01}
            min={0}
            max={1}
            value={data.normfreq}
            onChange={(e) =>
              handleUpdateProperty({
                normfreq: Number(e.target.value),
              })
            }
          />
          <span className="text-right font-light">{data.normfreq}</span>
        </Property>
      </Node>
      <OutHandle />
    </>
  );
};

export default BitCrusher;
