import { Handle, Position } from 'reactflow';

const OutHandle = () => {
  return (
    <Handle
      className="-bottom-2.5 -z-10 h-5 w-5"
      type="source"
      position={Position.Bottom}
    />
  );
};

export default OutHandle;
