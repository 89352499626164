import { useAudioasisDispatch } from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const sounds = [
  { name: 'birds', path: '/audio/loops/birds.ogg' },
  { name: 'bonfire', path: '/audio/loops/bonfire.ogg' },
  { name: 'breeze-autumn', path: '/audio/loops/breeze-autumn.ogg' },
  { name: 'brook', path: '/audio/loops/brook.ogg' },
  { name: 'cocktail-voices', path: '/audio/loops/cocktail-voices.ogg' },
  { name: 'coffee-house', path: '/audio/loops/coffee-house.ogg' },
  { name: 'creek', path: '/audio/loops/creek.ogg' },
  { name: 'frogs', path: '/audio/loops/frogs.ogg' },
  { name: 'heat-wave', path: '/audio/loops/heat-wave.ogg' },
  { name: 'meditation-time', path: '/audio/loops/meditation-time.ogg' },
  { name: 'noise-brown', path: '/audio/loops/noise-brown.ogg' },
  { name: 'noise-fan', path: '/audio/loops/noise-fan.ogg' },
  { name: 'noise-pink', path: '/audio/loops/noise-pink.ogg' },
  { name: 'noise-white', path: '/audio/loops/noise-white.ogg' },
  { name: 'rain-drops', path: '/audio/loops/rain-drops.ogg' },
  { name: 'rain-pouring', path: '/audio/loops/rain-pouring.ogg' },
  { name: 'shore-calm', path: '/audio/loops/shore-calm.ogg' },
  { name: 'shore', path: '/audio/loops/shore.ogg' },
  { name: 'stream', path: '/audio/loops/stream.ogg' },
  { name: 'summer-night', path: '/audio/loops/summer-night.ogg' },
  { name: 'thunder-close', path: '/audio/loops/thunder-close.ogg' },
  { name: 'thunder-distant', path: '/audio/loops/thunder-distant.ogg' },
  { name: 'waterfall-close', path: '/audio/loops/waterfall-close.ogg' },
  { name: 'waterfall-distant', path: '/audio/loops/waterfall-distant.ogg' },
  { name: 'waves-large', path: '/audio/loops/waves-large.ogg' },
  { name: 'waves-ocean', path: '/audio/loops/waves-ocean.ogg' },
  { name: 'wild-shore', path: '/audio/loops/wild-shore.ogg' },
  { name: 'wind-chimes', path: '/audio/loops/wind-chimes.ogg' },
  { name: 'wind-coastal', path: '/audio/loops/wind-coastal.ogg' },
  { name: 'wind-forest', path: '/audio/loops/wind-forest.ogg' },
];

const Loop = ({ id, data }) => {
  const dispatch = useAudioasisDispatch();

  const handleUpdateAudioElement = async (e) => {
    dispatch({
      type: 'updateLoop',
      payload: { id: id, audioPath: e.target.value },
    });
  };

  return (
    <>
      <Node>
        <Header name="Loop" color="orange" />

        <Property>
          <select
            className="nodrag rounded-md bg-neutral-300"
            value={data.audioPath}
            onChange={(e) => handleUpdateAudioElement(e)}
          >
            {sounds.map((sound) => {
              const { name, path } = sound;
              return (
                <option key={name} value={path}>
                  {name}
                </option>
              );
            })}
          </select>
        </Property>
      </Node>
      <OutHandle />
    </>
  );
};

export default Loop;
