const Property = ({ name, visible = true, children }) => {
  return visible ? (
    <>
      {name && <div className="mx-auto my-2 h-[1px] w-[90%] bg-neutral-400" />}
      <span className=" w-fit bg-inherit pl-1 pr-0.5 text-xs">{name}</span>

      <label className="flex flex-col space-x-1 p-1 text-xs">{children}</label>
    </>
  ) : (
    <></>
  );
};

export default Property;
