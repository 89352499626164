import { useAudioasisDispatch } from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const Oscillator = ({ id, data }) => {
  const dispatch = useAudioasisDispatch();

  const handleUpdateProperty = (updatedProperty) => {
    const newData = { ...data, ...updatedProperty };
    dispatch({
      type: 'nodeDataUpdate',
      payload: {
        id: id,
        data: newData,
      },
    });
  };

  return (
    <>
      <Node>
        <Header name="Oscillator" color="pink" />

        <Property name="Frequency">
          <input
            className="nodrag"
            type="range"
            step={1}
            min={10}
            max={15000}
            value={data.frequency}
            onChange={(e) =>
              handleUpdateProperty({ frequency: e.target.value })
            }
          />
          <span className="text-right font-light">
            {Number(data.frequency)}Hz
          </span>
        </Property>

        <Property name="Waveform">
          <select
            className="nodrag rounded-md bg-neutral-300"
            value={data.type}
            onChange={(e) => handleUpdateProperty({ type: e.target.value })}
          >
            <option value="sine">sine</option>
            <option value="triangle">triangle</option>
            <option value="sawtooth">sawtooth</option>
            <option value="square">square</option>
          </select>
        </Property>
      </Node>

      <OutHandle />
    </>
  );
};

export default Oscillator;
