import {
  FolderArrowDownIcon,
  FolderOpenIcon,
} from '@heroicons/react/24/outline';
import { ControlButton, Controls, useReactFlow } from 'reactflow';

import { useAudioasisDispatch } from '../../context/audioasisContext';

const CustomControls = () => {
  const dispatch = useAudioasisDispatch();
  const flow = useReactFlow();

  const handleSaveFlow = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(flow.toObject())], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'audiOasis.json';
    document.body.appendChild(element);
    element.click();
  };

  const handleLoadFlow = (e) => {
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];
    file.text().then((text) => {
      let loadingFlow = JSON.parse(text);
      const { nodes, edges, viewport } = loadingFlow;

      flow.setViewport(viewport);
      dispatch({ type: 'loadFlow', payload: { nodes: nodes, edges: edges } });
    });
    e.target.value = '';
  };

  return (
    <Controls>
      <input
        id="loadFile"
        type="file"
        accept=".json"
        className={`hidden`}
        onChange={handleLoadFlow}
      ></input>
      <ControlButton onClick={handleSaveFlow} title="Save">
        <div>
          <FolderArrowDownIcon />
        </div>
      </ControlButton>
      <ControlButton
        title="load"
        onClick={() => document.getElementById('loadFile').click()}
      >
        <div>
          <FolderOpenIcon></FolderOpenIcon>
        </div>
      </ControlButton>
    </Controls>
  );
};

export default CustomControls;
