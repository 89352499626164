import { useAudioasisDispatch } from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import InHandle from '../Layout/InHandle';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const Delay = ({ id, data }) => {
  const dispatch = useAudioasisDispatch();

  const handleUpdateProperty = (updatedProperty) => {
    const newData = { ...data, ...updatedProperty };
    dispatch({
      type: 'nodeDataUpdate',
      payload: {
        id: id,
        data: newData,
      },
    });
  };

  return (
    <>
      <InHandle />
      <Node>
        <Header name="Delay" color="pink" />

        <Property name="Delay Time">
          <input
            className="nodrag"
            type="range"
            step={0.001}
            min={0}
            max={1}
            value={data.delayTime}
            onChange={(e) =>
              handleUpdateProperty({ delayTime: e.target.value })
            }
          />
          <span className="text-right font-light">
            {Number(data.delayTime)} seconds
          </span>
        </Property>
      </Node>

      <OutHandle />
    </>
  );
};

export default Delay;
