import { CheckCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

const colors = {
  pink: 'bg-pink-400',
  blue: 'bg-blue-400',
  green: 'bg-green-400',
  yellow: 'bg-yellow-400',
  red: 'bg-red-400',
  purple: 'bg-purple-400',
  teal: 'bg-teal-400',
  orange: 'bg-orange-400',
};

const Header = ({ name, color }) => {
  const [text, setText] = useState(name);
  const [edit, setEdit] = useState(false);

  return (
    <>
      {edit ? (
        <p
          className={`flex w-full flex-row items-center justify-between rounded-t-md p-1 text-sm text-neutral-100 ${colors[color]}`}
        >
          <input
            type="text"
            placeholder="Enter new name"
            className={`w-full rounded-t-md p-1 text-sm text-neutral-100 ${colors[color]}`}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') setEdit(!edit);
            }}
          />
          <CheckCircleIcon
            className="ml-5 h-5 w-5"
            onClick={() => setEdit(!edit)}
          />
        </p>
      ) : (
        <p
          className={`flex w-full flex-row items-center justify-between rounded-t-md p-1 text-sm text-neutral-100 ${colors[color]}`}
        >
          {text.length > 15 ? text.slice(0, 15) + '...' : text}
          <PencilSquareIcon
            className="ml-5 h-4 w-4"
            onClick={() => setEdit(!edit)}
          />
        </p>
      )}
    </>
  );
};

export default Header;
export { colors };
