import {
  useAudioasis,
  useAudioasisDispatch,
} from '../../context/audioasisContext';

const ContextMenuNode = () => {
  const {
    nodeContextIsOpen,
    nodeContextPosition: { x, y },
    focusedNode,
  } = useAudioasis();
  const dispatch = useAudioasisDispatch();

  return nodeContextIsOpen ? (
    <div
      className={`absolute z-50 flex w-[150px] flex-col rounded-md border border-slate-500 bg-slate-50 p-5`}
      style={{ top: y - 15, left: x - 150 / 2 }}
    >
      <button
        onClick={() => dispatch({ type: 'nodeDelete', payload: [focusedNode] })}
      >
        Delete
      </button>
    </div>
  ) : null;
};

export default ContextMenuNode;
