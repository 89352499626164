import { useAudioasisDispatch } from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import InHandle from '../Layout/InHandle';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const Gain = ({ id, data }) => {
  const dispatch = useAudioasisDispatch();

  const handleUpdateProperty = (updatedProperty) => {
    const newData = { ...data, ...updatedProperty };

    dispatch({
      type: 'nodeDataUpdate',
      payload: {
        id: id,
        data: newData,
      },
    });
  };

  return (
    <>
      <InHandle />
      <Node>
        <Header name="Gain" color="blue" />

        <Property name="Gain">
          <input
            className="nodrag"
            type="range"
            step="0.01"
            min="0"
            max="3"
            value={data.gain}
            onChange={(e) => handleUpdateProperty({ gain: e.target.value })}
          />
          <span className="text-right font-light">
            {Number(data.gain).toFixed(2)}dB
          </span>
        </Property>
      </Node>
      <OutHandle />
    </>
  );
};

export default Gain;
