import { useEffect, useRef } from 'react';
import { useAudioasis } from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import InHandle from '../Layout/InHandle';
import Node from '../Layout/Node';
import Property from '../Layout/Property';

const Vizualizer = ({ id }) => {
  const state = useAudioasis();

  const canvasRef = useRef(null);
  const canvasCtxRef = useRef(null);

  const { audioNodes } = state;
  const audioNode = audioNodes.get(id);

  let canvas, ctx, bufferLength, dataArray, barWidth, barHeight, x;

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (canvasRef.current) {
      canvasCtxRef.current = canvasRef.current.getContext('2d');
      canvas = canvasRef.current;
      ctx = canvasCtxRef.current;

      ctx.canvas.width = 300;
      ctx.canvas.height = 50;

      x = 0;

      bufferLength = audioNode.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);
      barWidth = canvas.width / bufferLength;
    }

    draw();
  };

  const draw = () => {
    x = 0;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    audioNode.getByteFrequencyData(dataArray);

    for (let i = 0; i < bufferLength; i++) {
      barHeight = (dataArray[i] + 10) / 5;
      ctx.fillStyle = 'black';
      ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
      x += barWidth;
    }

    requestAnimationFrame(draw);
  };

  return (
    <>
      <InHandle />
      <Node>
        <Header name="Visualizer" color="green" />

        <Property>
          <canvas ref={canvasRef}></canvas>
        </Property>
      </Node>
    </>
  );
};

export default Vizualizer;
