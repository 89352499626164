import * as Sentry from '@sentry/react';
import { Analytics } from '@vercel/analytics/react';
import Flow from './components/Flow';
import { AudiOasisProvider } from './context/audioasisContext';

Sentry.init({
  dsn: 'https://1794c4bf98064d34bffa199d2a2fce5b@o4505422228881408.ingest.sentry.io/4505422327971840',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  return (
    <>
      <Analytics />
      <AudiOasisProvider>
        <div className="h-screen w-screen">
          <Flow />
        </div>
      </AudiOasisProvider>
    </>
  );
}

export default App;
