import { useAudioasisDispatch } from '../../../context/audioasisContext';

import Header from '../Layout/Header';
import InHandle from '../Layout/InHandle';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const BiquadFilter = ({ id, data }) => {
  const dispatch = useAudioasisDispatch();

  const handleUpdateProperty = (updatedProperty) => {
    const newData = { ...data, ...updatedProperty };

    dispatch({
      type: 'nodeDataUpdate',
      payload: {
        id: id,
        data: newData,
      },
    });
  };

  return (
    <>
      <InHandle />
      <Node
        canCollapse
        onToggleCollapse={() =>
          handleUpdateProperty({ collapsed: !data.collapsed })
        }
      >
        <Header name="Biquad Filter" color="purple" />

        <Property>
          <select
            className="nodrag rounded-md bg-neutral-300"
            value={data.type}
            onChange={(e) => handleUpdateProperty({ type: e.target.value })}
          >
            <option value="bandpass">bandpass</option>
            <option value="lowpass">lowpass</option>
            <option value="highpass">highpass</option>
            <option value="lowshelf">lowshelf</option>
            <option value="highshelf">highshelf</option>
            <option value="peaking">peaking</option>
            <option value="notch">notch</option>
            <option value="allpass">allpass</option>
          </select>
        </Property>

        <Property name="Frequency">
          <input
            className="nodrag"
            type="range"
            step={1}
            min={10}
            max={1000}
            value={data.frequency}
            onChange={(e) =>
              handleUpdateProperty({ frequency: e.target.value })
            }
          />
          <span className="text-right font-light">{data.frequency}Hz</span>
        </Property>

        <Property name="Detune" visible={!data.collapsed}>
          <input
            className="nodrag"
            type="range"
            step={1}
            min={10}
            max={1000}
            value={data.detune}
            onChange={(e) => handleUpdateProperty({ detune: e.target.value })}
          />
          <span className="text-right font-light">{data.detune} cents</span>
        </Property>

        <Property name="Gain" visible={!data.collapsed}>
          <input
            className="nodrag"
            type="range"
            step={0.1}
            min={0}
            max={3}
            value={data.gain}
            onChange={(e) => handleUpdateProperty({ gain: e.target.value })}
          />
          <span className="text-right font-light">{data.gain}dB</span>
        </Property>

        <Property name="Quality Factor" visible={!data.collapsed}>
          <input
            className="nodrag"
            type="range"
            step={1}
            min={0}
            max={50}
            value={data.Q}
            onChange={(e) => handleUpdateProperty({ Q: e.target.value })}
          />
          <span className="text-right font-light">{data.Q}</span>
        </Property>
      </Node>
      <OutHandle />
    </>
  );
};

export default BiquadFilter;
