import { BoltIcon, BoltSlashIcon } from '@heroicons/react/24/outline';
import {
  useAudioasis,
  useAudioasisDispatch,
} from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const Noise = () => {
  const dispatch = useAudioasisDispatch();
  const { isPlaying } = useAudioasis();

  return (
    <>
      <Node>
        <Header name="Noise" color="red" />

        <Property>
          <div
            className="nodrag flex justify-center p-1"
            onClick={() => dispatch({ type: 'togglePlaying' })}
          >
            {isPlaying ? (
              <BoltIcon className="h-6 w-6" />
            ) : (
              <BoltSlashIcon className="h-6 w-6" />
            )}
          </div>
        </Property>
      </Node>

      <OutHandle />
    </>
  );
};

export default Noise;
