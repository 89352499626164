import {
  useAudioasis,
  useAudioasisDispatch,
} from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';

const MP3 = ({ id, data: { buffer } }) => {
  const dispatch = useAudioasisDispatch();
  const { audioContext } = useAudioasis();

  const handleFileUpload = (e) => {
    if (e.target.files.length === 0) return;

    const file = e.target.files[0];
    file.arrayBuffer().then((buffer) => {
      audioContext.decodeAudioData(buffer).then((audioBuffer) => {
        const updatedProperty = {
          buffer: audioBuffer,
        };

        dispatch({
          type: 'loadMp3',
          payload: {
            id: id,
            data: updatedProperty,
          },
        });
      });
    });
  };

  return (
    <>
      <Node>
        <Header name="MP3" color="teal" />
        <div>
          <label
            className={`cursor-pointer border border-solid border-neutral-700 pl-4 pr-4`}
          >
            Load MP3
            <input
              className={`hidden`}
              type="file"
              accept=".mp3"
              onChange={(e) => handleFileUpload(e)}
            />
          </label>
        </div>
      </Node>
      <OutHandle />
    </>
  );
};

export default MP3;
