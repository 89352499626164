import { nanoid } from 'nanoid';
import { createContext, useContext, useReducer } from 'react';
import { applyEdgeChanges, applyNodeChanges } from 'reactflow';

import {
  CreateBiquadFilterNode,
  CreateBitCrusherNode,
  CreateDelayNode,
  CreateGainNode,
  CreateLoopNode,
  CreateMp3Node,
  CreateMuteNode,
  CreateNoiseNode,
  CreateOscilatorNode,
  CreateScopeNode,
  CreateVisualizerNode,
} from '../nodes/nodeHelper';

import BiquadFilter from '../components/Flow/Nodes/BiquadFilter';
import BitCrusher from '../components/Flow/Nodes/BitCrusher';
import Delay from '../components/Flow/Nodes/Delay';
import Gain from '../components/Flow/Nodes/Gain';
import Loop from '../components/Flow/Nodes/Loop';
import MP3 from '../components/Flow/Nodes/MP3';
import Mute from '../components/Flow/Nodes/Mute';
import Noise from '../components/Flow/Nodes/Noise';
import Oscillator from '../components/Flow/Nodes/Oscillator';
import Out from '../components/Flow/Nodes/Out';
import Scope from '../components/Flow/Nodes/Scope';
import Visualizer from '../components/Flow/Nodes/Visualizer';

const AudiOasisContext = createContext(null);
const AudiOasisDispatchContext = createContext(null);

export function AudiOasisProvider({ children }) {
  initialState.audioNodes.set('out', initialState.audioContext.destination);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AudiOasisContext.Provider value={state}>
      <AudiOasisDispatchContext.Provider value={dispatch}>
        {children}
      </AudiOasisDispatchContext.Provider>
    </AudiOasisContext.Provider>
  );
}

export function useAudioasis() {
  return useContext(AudiOasisContext);
}

export function useAudioasisDispatch() {
  return useContext(AudiOasisDispatchContext);
}

export const nodeTypes = {
  // sources (orange)
  loop: Loop,
  mp3: MP3,

  // generators (pink)
  osc: Oscillator,
  noise: Noise,

  // processors (blue)
  mute: Mute,
  gain: Gain,
  biquadFilter: BiquadFilter,
  delay: Delay,

  // effects (purple)
  bitCrusher: BitCrusher,

  // outputs (green)
  out: Out,
  scope: Scope,
  vis: Visualizer,
};

function reducer(state, { type, payload }) {
  switch (type) {
    case 'loadFlow': {
      const loadingAudioNodes = new Map();
      const loadingAudioElements = new Map();
      const loadingNodes = [];
      const { nodes, edges } = payload;
      const audioContext = new AudioContext();
      const { audioContext: oldContext } = state;
      oldContext.close();

      //create audio nodes
      nodes.forEach(
        ({
          data: nodeData,
          id,
          type,
          position,
          width,
          height,
          deleteable,
          selected,
          positionAbsolute,
          dragging,
        }) => {
          switch (type) {
            case 'out': {
              loadingAudioNodes.set('out', audioContext.destination);
              break;
            }
            case 'osc': {
              loadingAudioNodes.set(
                id,
                CreateOscilatorNode(audioContext, nodeData)
              );
              break;
            }

            case 'gain': {
              loadingAudioNodes.set(id, CreateGainNode(audioContext, nodeData));
              break;
            }

            case 'loop': {
              const { audioElement, audioNode } = CreateLoopNode(
                audioContext,
                nodeData,
                id
              );

              loadingAudioElements.set(id, audioElement);
              loadingAudioNodes.set(id, audioNode);
              break;
            }

            case 'scope': {
              loadingAudioNodes.set(id, CreateScopeNode(audioContext));
              break;
            }

            case 'vis': {
              loadingAudioNodes.set(id, CreateVisualizerNode(audioContext));
              break;
            }

            case 'noise': {
              loadingAudioNodes.set(id, CreateNoiseNode(audioContext));
              break;
            }

            case 'biquadFilter': {
              loadingAudioNodes.set(
                id,
                CreateBiquadFilterNode(audioContext, nodeData)
              );
              break;
            }

            case 'bitCrusher': {
              loadingAudioNodes.set(
                id,
                CreateBitCrusherNode(audioContext, nodeData)
              );
              break;
            }

            case 'mp3': {
              loadingAudioNodes.set(id, CreateMp3Node(audioContext, nodeData));
              break;
            }

            case 'delay': {
              loadingAudioNodes.set(
                id,
                CreateDelayNode(audioContext, nodeData)
              );
              break;
            }

            case 'mute': {
              loadingAudioNodes.set(id, CreateMuteNode(audioContext, nodeData));
              break;
            }

            default:
              break;
          }

          loadingNodes.push({
            width: width,
            height: height,
            type: type,
            id: id,
            data: nodeData,
            position: position,
            deleteable: deleteable,
            selected: selected,
            positionAbsolute: positionAbsolute,
            dragging: dragging,
          });
        }
      );

      // connect audio nodes
      edges.forEach((edge) => {
        const { source, target } = edge;
        const audioSource = loadingAudioNodes.get(source);
        const audioTarget = loadingAudioNodes.get(target);
        audioSource && audioTarget && audioSource.connect(audioTarget);
      });

      audioContext.resume();

      return {
        ...state,
        audioContext: audioContext,
        nodes: nodes,
        edges: edges,
        audioNodes: loadingAudioNodes,
        audioElements: loadingAudioElements,
      };
    }

    case 'loading': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'togglePlaying': {
      const { audioContext, isPlaying, edges } = state;
      const updatedEdges = [...edges];
      if (isPlaying) {
        updatedEdges.forEach((e) => {
          e.animated = false;
        });
        audioContext.suspend();
      } else {
        updatedEdges.forEach((e) => {
          e.animated = true;
        });
        audioContext.resume();
      }

      return {
        ...state,
        isLoading: false,
        isPlaying: !isPlaying,
        edges: updatedEdges,
      };
    }

    case 'nodeUpdate': {
      const { nodeUpdates } = payload;
      const { nodes } = state;

      return {
        ...state,
        isLoading: false,
        nodes: applyNodeChanges(nodeUpdates, nodes),
      };
    }

    case 'nodeDataUpdate': {
      const { data, id } = payload;
      const { audioNodes, audioContext } = state;
      const audioNode = audioNodes.get(id);

      for (const [key, val] of Object.entries(data)) {
        if (audioNode[key] instanceof AudioParam) {
          audioNode[key].linearRampToValueAtTime(
            val,
            audioContext.currentTime + 0.05
          );
        } else {
          audioNode[key] = val;
        }
      }

      return {
        ...state,
        isLoading: false,
        nodes: state.nodes.map((node) =>
          node.id === id ? { ...node, data: data } : node
        ),
      };
    }

    case 'edgeUpdate': {
      const { edgeUpdates } = payload;
      const edges = [...state.edges];

      edgeUpdates.forEach(({ type, id }) => {
        switch (type) {
          case 'remove': {
            let edge = edges.find((e) => e.id === id);
            const { source, target } = edge;
            const audioSource = state.audioNodes.get(source);
            const audioTarget = state.audioNodes.get(target);
            audioSource && audioTarget && audioSource.disconnect(audioTarget);
            break;
          }

          default:
            break;
        }
      });

      return {
        ...state,
        isLoading: false,
        edges: applyEdgeChanges(edgeUpdates, edges),
      };
    }

    case 'loadMp3': {
      const {
        id,
        data: { buffer },
      } = payload;
      const { audioNodes, audioContext, edges } = state;

      // remove existing audioNode
      const existingAudioNode = audioNodes.get(id);
      if (existingAudioNode) {
        existingAudioNode.disconnect();
      }

      let data = { buffer: buffer };
      const audioNode = audioContext.createBufferSource();
      audioNode.buffer = data.buffer;
      audioNode.loop = false;
      audioNode.start();

      let effectedEdges = edges.filter((edge) => edge.source === id);
      effectedEdges.forEach((edge) => {
        const { target } = edge;
        const audioTarget = audioNodes.get(target);
        audioNode.connect(audioTarget);
      });

      let updatedAudioNodes = audioNodes.set(id, audioNode);

      return {
        ...state,
        audioNodes: updatedAudioNodes,
      };
    }

    case 'updateLoop': {
      const { id, audioPath } = payload;
      const { audioElements, audioNodes } = state;

      const audioElement = audioElements.get(id);
      audioElement.src = audioPath;
      const updatedAudioElements = audioElements.set(id, audioElement);
      audioElement.play();

      const audioNode = audioNodes.get(id);
      audioNode['audioPath'] = audioPath;

      return {
        ...state,
        audioElements: updatedAudioElements,
        nodes: state.nodes.map((node) =>
          node.id === id ? { ...node, data: { audioPath: audioPath } } : node
        ),
      };
    }

    case 'edgeAdd': {
      const { edgeParams, providedId } = payload;

      const { source: sourceId, target: targetId } = edgeParams;

      const id = providedId || nanoid(6);
      const newFlowEdge = { ...edgeParams, id: id };
      const audioSource = state.audioNodes.get(sourceId);
      const audioTarget = state.audioNodes.get(targetId);

      audioSource.connect(audioTarget);

      return {
        ...state,
        isLoading: false,
        edges: [...state.edges, newFlowEdge],
      };
    }

    case 'nodeDelete': {
      const updatedAudioNodes = state.audioNodes;

      payload.forEach(({ id, type }) => {
        const audioNode = updatedAudioNodes.get(id);
        if (audioNode) {
          if (['sine', 'square', 'sawtooth', 'triangle'].includes(type)) {
            audioNode.stop();
          }

          audioNode.disconnect();
          updatedAudioNodes.delete(id);
        }
      });

      return {
        ...state,
        isLoading: false,
        audioNodes: updatedAudioNodes,
        nodes: state.nodes.filter(
          (node) => !payload.map((o) => o.id).includes(node.id)
        ),
      };
    }

    case 'openNodeContextMenu': {
      const { x, y, node } = payload;
      return {
        ...state,
        contextMenuNode: node,
        nodeContextPosition: { x, y },
        focusedNode: node,
        nodeContextIsOpen: true,
        flowContextIsOpen: false,
        edgeContextIsOpen: false,
      };
    }

    case 'closeNodeContextMenu': {
      return {
        ...state,
        nodeContextIsOpen: false,
      };
    }

    case 'openFlowContextMenu': {
      const { x, y } = payload;
      return {
        ...state,
        flowContextPosition: { x, y },
        flowContextIsOpen: true,
        nodeContextIsOpen: false,
        edgeContextIsOpen: false,
      };
    }

    case 'closeFlowContextMenu': {
      return {
        ...state,
        flowContextIsOpen: false,
      };
    }

    case 'openEdgeContextMenu': {
      const { x, y } = payload;
      return {
        ...state,
        edgeContextPosition: { x, y },
        edgeContextIsOpen: true,
        nodeContextIsOpen: false,
        flowContextIsOpen: false,
      };
    }

    case 'closeEdgeContextMenu': {
      return {
        ...state,
        edgeContextIsOpen: false,
      };
    }

    case 'closeAllContextMenus': {
      return {
        ...state,
        nodeContextIsOpen: false,
        flowContextIsOpen: false,
        edgeContextIsOpen: false,
      };
    }

    case 'nodeAdd': {
      const autoId = nanoid(6);
      const id = payload.providedId || autoId;
      let data;
      let updatedAudioNodes;
      let updatedAudioElements = state.audioElements;

      const { nodeType, viewport, data: providedData } = payload;
      const { audioNodes, audioContext } = state;

      const position = {
        x: viewport.x + ((15 * state.nodes.length) % 100),
        y: viewport.y + ((15 * state.nodes.length) % 100),
      };

      switch (nodeType) {
        case 'osc': {
          data = providedData || { frequency: 440, type: 'sine' };
          updatedAudioNodes = audioNodes.set(
            id,
            CreateOscilatorNode(audioContext, data)
          );
          break;
        }

        case 'gain': {
          data = providedData || { gain: 0.5 };
          updatedAudioNodes = audioNodes.set(
            id,
            CreateGainNode(audioContext, data)
          );
          break;
        }

        case 'loop': {
          data = providedData || { audioPath: '/audio/loops/birds.ogg' };
          const { audioElement, audioNode } = CreateLoopNode(
            audioContext,
            data,
            id
          );

          updatedAudioElements.set(id, audioElement);
          updatedAudioNodes = audioNodes.set(id, audioNode);
          break;
        }

        case 'scope': {
          updatedAudioNodes = audioNodes.set(id, CreateScopeNode(audioContext));
          break;
        }

        case 'vis': {
          updatedAudioNodes = audioNodes.set(
            id,
            CreateVisualizerNode(audioContext)
          );
          break;
        }

        case 'noise': {
          updatedAudioNodes = audioNodes.set(id, CreateNoiseNode(audioContext));
          break;
        }

        case 'biquadFilter': {
          data = providedData || {
            frequency: 440,
            type: 'bandpass',
            detune: 440,
            Q: 1,
            gain: 0,
            collapsed: true,
          };

          updatedAudioNodes = audioNodes.set(
            id,
            CreateBiquadFilterNode(audioContext, data)
          );
          break;
        }

        case 'bitCrusher': {
          data = providedData || { bits: 4, normfreq: 0.1 };
          updatedAudioNodes = audioNodes.set(
            id,
            CreateBitCrusherNode(audioContext, data)
          );
          break;
        }

        case 'mp3': {
          data = { buffer: null };
          updatedAudioNodes = audioNodes.set(
            id,
            CreateMp3Node(audioContext, data)
          );
          break;
        }

        case 'delay': {
          data = providedData || { delayTime: 0 };
          updatedAudioNodes = audioNodes.set(
            id,
            CreateDelayNode(audioContext, data)
          );
          break;
        }

        case 'mute': {
          data = providedData || { gain: 1 };
          updatedAudioNodes = audioNodes.set(
            id,
            CreateMuteNode(audioContext, data)
          );
          break;
        }

        default:
          break;
      }

      return {
        ...state,
        isLoading: false,
        nodes: [
          ...state.nodes,
          {
            id: id,
            type: nodeType,
            data: data,
            position: position,
            deletable: true,
          },
        ],
        audioNodes: updatedAudioNodes,
        audioElements: updatedAudioElements,
      };
    }

    default:
      return state;
  }
}

const initialState = {
  isLoading: true,
  isPlaying: true,
  nodeContextPosition: { x: 0, y: 0 },
  nodeContextIsOpen: false,
  flowContextPosition: { x: 0, y: 0 },
  flowContextIsOpen: false,
  edgeContextPosition: { x: 0, y: 0 },
  edgeContextIsOpen: false,
  audioContext: new AudioContext(),
  audioNodes: new Map(),
  audioElements: new Map(),
  nodes: [
    {
      type: 'out',
      id: 'out',
      data: {},
      position: { x: 0, y: 0 },
      deletable: false,
    },
  ],
  edges: [],
};
