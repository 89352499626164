import { useReactFlow } from 'reactflow';

const PanelButton = ({ name, handleCreateNode }) => {
  const { project } = useReactFlow();

  const createNode = (name) => {
    const rect = document
      .querySelector('.react-flow__renderer')
      .getBoundingClientRect();
    const xy = { x: rect.width / 2, y: rect.height / 2 };

    handleCreateNode(name, project(xy));
  };

  return (
    <button
      key={name}
      className="m-1 w-44 rounded-md bg-neutral-300 shadow-lg transition duration-500 hover:scale-110"
      onClick={() => createNode(name)}
    >
      {name}
    </button>
  );
};

export default PanelButton;
