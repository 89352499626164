import { useAudioasis } from '../../context/audioasisContext';

const ContextMenuEdge = () => {
  const {
    edgeContextIsOpen,
    edgeContextPosition: { x, y },
  } = useAudioasis();

  return edgeContextIsOpen ? (
    <div
      className={` absolute z-50 flex w-[150px] flex-col rounded-md border border-slate-500 bg-slate-50 p-5 `}
      style={{ top: y - 15, left: x - 150 / 2 }}
    >
      Edge Context Menu
    </div>
  ) : null;
};

export default ContextMenuEdge;
