import { createBitCrusher } from './effects/createBitCrusher';

export const CreateOscilatorNode = (ctx, data) => {
  const audioNode = ctx.createOscillator();
  audioNode.frequency.value = Number(data.frequency);
  audioNode.type = data.type;
  audioNode.start();
  return audioNode;
};

export const CreateGainNode = (ctx, data) => {
  const audioNode = ctx.createGain();
  audioNode.gain.value = Number(data.gain);
  return audioNode;
};

export const CreateLoopNode = (ctx, data, id) => {
  const audioElement = new Audio(data.audioPath);
  audioElement.setAttribute('loop', true);
  audioElement.setAttribute('id', `audio-${id}`);

  const audioNode = ctx.createMediaElementSource(audioElement);
  audioElement.load();
  audioElement.play();
  return { audioNode, audioElement };
};

export const CreateScopeNode = (ctx) => {
  const analyser = ctx.createAnalyser();
  analyser.fftSize = 2048;
  return analyser;
};

export const CreateVisualizerNode = (ctx) => {
  const analyser = ctx.createAnalyser();
  analyser.fftSize = 2048;
  return analyser;
};

export const CreateNoiseNode = (ctx) => {
  const bufferSize = ctx.sampleRate * 2;
  const noiseBuffer = new AudioBuffer({
    length: bufferSize,
    sampleRate: ctx.sampleRate,
  });
  const noise = noiseBuffer.getChannelData(0);
  for (let i = 0; i < bufferSize; i++) {
    noise[i] = Math.random() * 2 - 1;
  }
  const audioNode = ctx.createBufferSource(noise);
  audioNode.buffer = noiseBuffer;
  audioNode.loop = true;
  audioNode.start();
  return audioNode;
};

export const CreateBiquadFilterNode = (ctx, data) => {
  const audioNode = ctx.createBiquadFilter();
  audioNode.frequency.value = Number(data.frequency);
  audioNode.type = data.type;
  audioNode.detune.value = Number(data.detune);
  audioNode.Q.value = Number(data.Q);
  audioNode.gain.value = Number(data.gain);
  return audioNode;
};

export const CreateBitCrusherNode = (ctx, data) => {
  const audioNode = createBitCrusher(ctx, 4096, data.bits, data.normfreq);
  audioNode.bits = data.bits;
  audioNode.normfreq = data.normfreq;
  return audioNode;
};

export const CreateMp3Node = (ctx, data) => {
  const audioNode = ctx.createBufferSource();
  audioNode.buffer = data.buffer;
  audioNode.loop = false;
  audioNode.start();
  return audioNode;
};

export const CreateDelayNode = (ctx, data) => {
  const audioNode = ctx.createDelay();
  audioNode.delayTime.value = Number(data.delayTime);
  return audioNode;
};

export const CreateMuteNode = (ctx, data) => {
  const audioNode = ctx.createGain();
  audioNode.gain.value = data.gain;
  return audioNode;
};
