import {
  nodeTypes,
  useAudioasis,
  useAudioasisDispatch,
} from '../../context/audioasisContext';
import PanelButton from './PanelButton';

const menu = [
  {
    title: 'NEW',
    subMenu: [
      {
        title: 'SOURCES',
        subMenu: [
          { title: 'OSCILLATOR', action: () => console.log('OSCILLATOR') },
        ],
      },
      {
        title: 'FILTERS',
        subMenu: [{ title: 'BIQUAD', action: () => console.log('BIQUAD') }],
      },
    ],
  },
  { title: 'DEBUG', action: () => console.log('DEBUG') },
];

const ContextMenuFlow = () => {
  const {
    flowContextIsOpen,
    flowContextPosition: { x, y },
  } = useAudioasis();
  const dispatch = useAudioasisDispatch();

  const handleCreateNode = (nodeType, viewport) => {
    dispatch({
      type: 'nodeAdd',
      payload: { nodeType: nodeType, viewport: viewport },
    });
  };

  return flowContextIsOpen ? (
    <div
      className={` absolute z-50 flex flex-col rounded-md border border-slate-500 bg-slate-50 p-5 `}
      style={{ top: y - 15, left: x - 150 / 2 }}
    >
      {Object.keys(nodeTypes).map((name) => {
        if (name !== 'out') {
          return (
            <PanelButton
              key={name}
              name={name}
              handleCreateNode={handleCreateNode}
            />
          );
        }
        return null;
      })}
    </div>
  ) : // <div className="absolute z-50" style={{ top: y - 15, left: x - 150 / 2 }}>
  //   <Menu></Menu>
  // </div>
  null;
};

export default ContextMenuFlow;
