import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/24/outline';
import { useAudioasisDispatch } from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import InHandle from '../Layout/InHandle';
import Node from '../Layout/Node';
import OutHandle from '../Layout/OutHandle';
import Property from '../Layout/Property';

const Mute = ({ id, data }) => {
  const dispatch = useAudioasisDispatch();

  const handleUpdateProperty = (updatedProperty) => {
    const newData = { ...data, ...updatedProperty };

    dispatch({
      type: 'nodeDataUpdate',
      payload: {
        id: id,
        data: newData,
      },
    });
  };

  return (
    <>
      <InHandle />
      <Node>
        <Header name="Mute" color="blue" />

        <Property>
          <div
            className="nodrag flex justify-center"
            onClick={() =>
              handleUpdateProperty({ gain: data.gain === 1 ? 0 : 1 })
            }
          >
            {data.gain === 1 ? (
              <SpeakerWaveIcon className="h-6 w-6" />
            ) : (
              <SpeakerXMarkIcon className="h-6 w-6" />
            )}
          </div>
        </Property>
      </Node>
      <OutHandle />
    </>
  );
};

export default Mute;
