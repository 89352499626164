import { PauseIcon, PlayIcon } from '@heroicons/react/24/outline';
import {
  useAudioasis,
  useAudioasisDispatch,
} from '../../../context/audioasisContext';
import Header from '../Layout/Header';
import InHandle from '../Layout/InHandle';
import Node from '../Layout/Node';
import Property from '../Layout/Property';

const Out = () => {
  const dispatch = useAudioasisDispatch();
  const { isPlaying } = useAudioasis();

  return (
    <>
      <InHandle />
      <Node>
        <Header name="Output" color="green" />
        <Property>
          <div
            className="nodrag flex justify-center"
            onClick={() => dispatch({ type: 'togglePlaying' })}
          >
            {isPlaying ? (
              <PauseIcon className="h-6 w-6" />
            ) : (
              <PlayIcon className="h-6 w-6" />
            )}
          </div>
        </Property>
      </Node>
    </>
  );
};

export default Out;
