const createBitCrusher = (
  audioContext,
  bufferSize = 4096,
  bits = 4,
  normfreq = 0.1
) => {
  var node = audioContext.createScriptProcessor(bufferSize, 1, 1);
  node.bits = bits; // between 1 and 16
  node.normfreq = normfreq; // between 0.0 and 1.0
  var phaser = 0;
  var last = 0;
  node.onaudioprocess = function (e) {
    var step = Math.pow(1 / 2, node.bits);
    var input = e.inputBuffer.getChannelData(0);
    var output = e.outputBuffer.getChannelData(0);
    for (var i = 0; i < bufferSize; i++) {
      phaser += node.normfreq;
      if (phaser >= 1.0) {
        phaser -= 1.0;
        last = step * Math.floor(input[i] / step + 0.5);
      }
      output[i] = last;
    }
  };
  return node;
};

export { createBitCrusher };
